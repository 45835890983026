import { PlanningConnectionRouter } from './planningConnection/planningConnectionPage'

import { createReactLifecycles } from 'single-spa-workfront'

const lifecycles = createReactLifecycles({
  suppressComponentDidCatchWarning: true,
  rootComponent: PlanningConnectionRouter,
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

export default PlanningConnectionRouter
